@import '../tokens/colors.scss';

.rk-link {
  text-decoration: none;
  cursor: pointer;
}

.rk-link:hover {
  text-decoration: underline;
}
