.rk-search {
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    input {
        border: 2px solid $color_cyan_200;
        border-radius: 30px;
        padding: 10px 15px;
        font-size: 1.2rem;
    }

    i.pi-search {
        left: 15px;
        color: $color_primary;
        font-size: 1.1rem;
    }
}