.rk-admin-table {
    .p-datatable {

        .p-datatable-thead>tr>th:nth-child(1),
        .p-datatable-thead>tr>th:nth-child(3) {
            width: 25%;
        }

        .p-datatable-thead>tr>th:nth-child(2) .p-datatable-thead>tr>th:nth-child(4) {
            width: 20%;
        }

        .p-datatable-thead>tr>th:nth-child(5) {
            width: 10%;
        }

        .flex {
            padding: 2rem;
        }
    }
}

.rk-lego-generated-table {
    .p-datatable {
        .p-datatable-thead>tr>th:nth-child(1) {
            width: 15%;
        }

        .p-datatable-thead>tr>th:nth-child(2) {
            width: 28%;
        }

        .p-datatable-thead>tr>th:nth-child(6) {
            width: 17%;
        }

        .p-datatable-thead>tr>th:nth-child(3) {
            width: 5%;
        }

        .p-datatable-thead>tr>th:nth-child(4) {
            width: 15%;
        }

        .p-datatable-thead>tr>th:nth-child(5),
        .p-datatable-thead>tr>th:nth-child(7) {
            width: 10%;
        }
    }
}

.rk-lego-execution-table {
    .p-datatable {

        .p-datatable-thead>tr>th:nth-child(1),
        .p-datatable-thead>tr>th:nth-child(3),
        .p-datatable-thead>tr>th:nth-child(5),
        .p-datatable-thead>tr>th:nth-child(6) {
            width: 15%;
        }

        .p-datatable-thead>tr>th:nth-child(2),
        .p-datatable-thead>tr>th:nth-child(4) {
            width: 20%;
        }
    }
}

.p-datatable {
    background-color: $color_white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;

    .p-datatable-thead>tr>th,
    .p-datatable-tbody>tr>td {
        padding: 1rem;
        border-bottom: 1px solid $color_gray_thin_100;
    }

    .p-datatable-thead>tr>th {
        background-color: $color_primary;
        color: $color_white;
        font-weight: bold;
        text-align: left;
        padding: 1rem;
        border-bottom: 2px solid $color_blue_300;
    }

    .p-datatable-tbody>tr>td {
        padding: 1rem;
        border-bottom: 1px solid $color_gray_thin_100;
        background-color: $color_white;
        color: $color_gray_thin_300;
    }

    p-tag {
        border-radius: 5px;
        padding: 0.2rem 0.5rem;
        font-size: 0.85rem;

        &[severity="success"] {
            background-color: $color_green_200;
            color: $color_white;
        }

        &[severity="warning"] {
            background-color: $color_yellow_300;
            color: $color_gray_thin_300;
        }

        &[severity="danger"] {
            background-color: $color_red_300;
            color: $color_white;
        }

        &[severity="info"] {
            background-color: $color_blue_300;
            color: $color_white;
        }
    }

    i[appIcon] {
        font-size: 1.25rem;
    }

    .flex {
        justify-content: center;
        font-size: 1rem;
        color: $color_gray_thin_200;
    }
}
