.p-card-content {
  min-height: 5rem;
}

.rk-tasks-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.rk-task-card {
  flex: 0 1 calc(25% - 1rem);
  min-width: 12rem;
}

@media (max-width: 1024px) {
  .rk-task-card {
    flex: 0 1 calc(33.33% - 1rem); 
  }
}

@media (max-width: 768px) {
  .rk-task-card {
    flex: 0 1 calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .rk-task-card {
    flex: 0 1 100%;
  }
}