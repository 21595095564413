@import 'primeflex/primeflex.scss';

.rk-expander {
  @include styleclass('transition-transform transition-duration-500');

  &.expanded {
    @include styleclass('rotate-180');
  }
}

.rk-icon-primary {
  color: $color_primary;
}

.rk-icon-secondary {
  color: $color_secondary;
}

.rk-icon-clickable {
  cursor: pointer;
}

.icon-center i:not(:last-child) {
  margin-right: 20px;
}
