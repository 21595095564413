@import '../tokens/typos.scss';
@import '../tokens/textures.scss';
@import '../tokens/colors.scss';

/*MIXIS*/

@mixin rk-headline--h1 {
  font-family: $font-family-primary;
  font-size: $typo-h1;
  font-weight: $weight-highlighted;
}

@mixin rk-headline--h2 {
  font-family: $font-family-primary;
  font-size: $typo-h2;
  font-weight: $weight-highlighted;
}

@mixin rk-headline--h3 {
  font-family: $font-family-primary;
  font-size: $typo-h3;
  font-weight: $weight-highlighted;
}

@mixin rk-headline--h4 {
  font-family: $font-family-primary;
  font-size: $typo-h4;
  font-weight: $weight-highlighted;
}

@mixin rk-headline--h5 {
  font-family: $font-family-primary;
  font-size: $typo-h5;
  font-weight: $weight-medium;
}

/* CLASSES */

.rk-headline--h1 {
  @include rk-headline--h1;
}

.rk-headline--h2 {
  @include rk-headline--h2;
}

.rk-headline--h3 {
  @include rk-headline--h3;
}

.rk-headline--h4 {
  @include rk-headline--h4;
}

.rk-headline--h5 {
  @include rk-headline--h5;
}

.rk-headline--sub-one {
  font-family: $font-family-primary;
  font-size: $typo-sub-one;
  font-weight: $weight-highlighted !important;
}

.rk-headline--sub-two {
  font-family: $font-family-primary;
  font-size: $typo-sub-two;
  font-weight: $weight-normal;
}

.rk-body--normal {
  font-family: $font-family-primary;
  font-size: $typo-body;
  font-weight: $weight-normal;
}

.rk-body--small {
  font-family: $font-family-primary;
  font-size: $typo-small;
  font-weight: $weight-normal;
}

.rk-body--caption {
  font-family: $font-family-primary;
  font-size: $typo-caption;
  font-weight: $weight-normal;
}

.rk-text-color--white {
  color: $color-white;
}

.rk-text-color--primary {
  color: $color_primary
}

.rk-text--capitalize {
  text-transform: capitalize;
}

.rk-text--bold {
  font-weight: bold;
}