.rk-form-filter-properties {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .properties-filter-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }

  .property-filter {
    display: flex;
    flex-direction: column;
  }

  .property-filter label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: $color_primary;
  }

  span,
  input,
  textarea,
  select {
    width: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    margin-bottom: 1rem;
  }

  .edit-mode {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
    border: 1px solid $color_cyan_200;
    border-radius: 4px;
    font-size: 0.9rem;
  }
}

.rk-button-filter-container {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}
