@import '../tokens/colors.scss';

.rk-validate-flag {
  display: flex;
  align-items: end;
  margin-left: 0.1rem;
  font-weight: bold;
  color: $color_red_300;
  font-size: 1.1rem;
}
