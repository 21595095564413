.rk-form-filter-properties {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .properties-filter-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }

  .property-filter {
    display: flex;
    flex-direction: column;
  }

  .property-filter label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: $color_primary;
  }

  span,
  input,
  textarea,
  select {
    width: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    margin-bottom: 1rem;
  }

  .edit-mode {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
    border: 1px solid $color_cyan_200;
    border-radius: 4px;
    font-size: 0.9rem;
  }
}

.rk-button-filter-container {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.rk-button-filter-container p-button {
  min-width: 120px;
  padding: 8px 12px;
  font-size: 0.9rem;
}

.p-calendar {
  box-sizing: border-box !important;
  width: 100% !important;
  border: 1px solid $color_cyan_200 !important;
  font-size: 0.9rem !important;
  padding: 0.3rem 0.4rem !important;
  height: 2.25rem !important;

  .p-icon {
    color: $color_primary !important;
  }

}