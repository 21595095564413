@import '../tokens/colors.scss';

a {
  text-decoration: none;
  font-weight: bold;
}

.rk-sidebar {
  background: transparent;
  margin-top: 2.6rem;
}

.rk-sidebar-submenu {
  list-style: none;
  padding: 5px 0px;
  overflow: hidden;
  list-style-type: none;
  margin: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.rk-sidebar-item {
  display: flex;
  flex-direction: column;
}

.rk-sidebar-item>a {
  width: 100%;
  text-decoration: none;
  color: #FFE946;
}

.rk-sidebar-item a:hover {
  background-color: $color_blue_400;
}

.rk-sidebar-item>a>em {
  margin: 20px;
  color: #FFFFFF;
  vertical-align: middle;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.3s;
  font-size: 0.9vw;
}

.rk-sidebar-item--active>a>.pi-angle-down {
  transform: rotate(180deg);
}

.rk-sidebar-item--arrow {
  float: right;
}

.rk-sidebar-item-link {
  padding: 0.3vw 0.2vw;
  color: #FFFFFF;
  border-radius: 20px 0px 0px 20px;
  width: 301px;
  display: flexbox;
  white-space: nowrap;
}

.rk-sidebar-item-link i {
  font-size: 1.4vw;
  color: $color_yellow_100;
  vertical-align: middle;
  margin: 1vw 1vw 1vw 1.2vw;
}

#rk-play {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color_blue_600;
  border-radius: 50%;
  height: 5.5vh;
  width: 5.5vh;
  position: relative;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

#rk-play .pi-play {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: rotate(0deg);
  font-size: 1.1vw;
  color: white;
  cursor: pointer;
}

#rk-play.isExpanded .pi-play {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  transform: rotate(180deg);
}

.rk-image-line {
  width: 6.1vh;
  height: 0.6vh;
}

#p-botton-img {
  margin-left: 0.6vh;
  padding-bottom: 10%;
}

.rk-list {
  margin: 0;
  padding: 0;
}

.col-12 {
  padding: 0.5vw
}

.rk-custom-hr {
  border: none;
  background-color: $color_blue_300;
  height: 2px;
  width: 90%;
  margin-left: 28px;
}

.rk-text-bar {
  display: none;
}

.rk-text-bar.isExpanded, .rk-text-submenu {
  display: inline;
  font-size: 0.9vw;
}

@media screen and (max-width: 768px) {

  .rk-text-bar.isExpanded, .rk-sidebar-item-link i, .rk-text-submenu {
    font-size: 1.5vw;
  }

  .rk-sidebar-item>a>em {
    margin: 10px;
    font-size: 1.5vw;
  }
}