.rk-top-tasks-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  height: 100%;
}

.rk-top-task-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rk-top-task-card {
  flex: 0 1 calc(20% - 1rem);
  min-width: 13rem;
  word-wrap: break-word;

  .p-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 17rem;
  }
}

.rk-top-task-name,
.rk-task-name {
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  font-size: 0.85rem;
  margin: 0;
}

.rk-top-task-name {
  color: rgba(0, 0, 0, 0.54);
}

.rk-task-name {
  font-size: 1.6rem;
  font-weight: $weight-highlighted;
}

.rk-top-task-header {
  margin-right: 1rem;
  padding: 0;
}

.rk-top-task-counter,
.rk-top-task-number {
  font-weight: $weight-highlighted;
  margin: 0;
}

.rk-top-task-counter {
  font-size: 1.8rem;
  color: $color_yellow_400;
}

.rk-top-task-number {
  font-size: 1.8rem;
  color: $color_primary;
  text-decoration: underline;
}

.rk-task-icon {
  color: $color_primary;
  font-size: 2rem;
}

.rk-task-content-container {
  overflow-wrap: break-word;
}

@media (max-width: 1024px) {
  .rk-top-task-card {
    flex: 0 1 calc(33.33% - 1rem);
  }
}

@media (max-width: 768px) {
  .rk-top-task-card {
    flex: 0 1 calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .rk-top-task-card {
    flex: 0 1 100%;
  }
}