//  ████████╗██╗···██╗██████╗··██████╗·
//  ╚══██╔══╝╚██╗·██╔╝██╔══██╗██╔═══██╗
//  ···██║····╚████╔╝·██████╔╝██║···██║
//  ···██║·····╚██╔╝··██╔═══╝·██║···██║
//  ···██║······██║···██║·····╚██████╔╝
//  ···╚═╝······╚═╝···╚═╝······╚═════╝·

/*Text*/
$text-align: left;

$typo-xxl: 5.63rem;
$typo-xl: 4.5rem;
$typo-l: 3.75rem;
$typo-h1: 3rem;
$typo-h2: 2.25rem;
$typo-h3: 2rem;
$typo-h4: 1.63rem;
$typo-h5: 1.38rem;
$typo-sub-one: 1.13rem;
$typo-sub-two: 1rem;
$typo-body: 1rem;
$typo-small: 0.75rem;
$typo-button: 1rem;
$typo-caption: 0.88rem;

/*Fonts*/

$font-family-primary: 'Sura Sans', sans-serif;

@font-face {
  font-family: 'Sura Sans';
  src: url('/assets/fonts/Sura Sans/SuraSans-Regular.otf') format('opentype');
}
