@import '../tokens/colors.scss';

.app-splash--dim {
  height: inherit;
  width: inherit;
}

.app-splash--background {
  background-color: $color_white;
}
