@import '../tokens/typos.scss';
@import '../tokens/colors.scss';
@import '../tokens/textures.scss';

* {
  box-sizing: border-box;
  font-family: $font-family-primary;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  font-size: $typo-body;
  font-weight: $weight-normal;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: $color_background;
}

.pi {
  font-family: $font-family-primary;
  font-size: $typo-body;
}

.pi::before {
  font-family: 'primeicons', sans-serif;
}
