@import '../tokens/typos.scss';
@import '../tokens/textures.scss';
@import '../tokens/colors.scss';

.rk-button {
  font-family: $font-family-primary;
  font-size: $typo-button;
  font-weight: $weight-highlighted;
}

.rk-button--primary {
  font-family: $font-family-primary;
  font-size: $typo-button;
  font-weight: $weight-highlighted;
  background-color: $color-primary;
  color: $color-white;
}
