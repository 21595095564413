@import '../tokens/colors.scss';

.rk-options {
  .p-button {
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
    margin-right: 1rem !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.rk-options.p-selectbutton .p-button.p-highlight {
  background-color: $color_primary;
  color: $color_white;
}
