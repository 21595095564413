.rk-icon-button {
  top: 10px;
  background: none;
  box-shadow: none;
  border: none;
  color: $color_primary;
  font-size: 1.5rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: transparent !important;
    color: $color_primary !important;
  }
}

.rk-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.rk-detail-card {
  position: relative;
  margin: auto;
  background-color: $color_white;
  padding: 0.5rem 2rem 2rem 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;


  .rk-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: $color_primary;
    font-size: 1.5rem;
  }
}

.rk-form-properties {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .properties-container {
    display: flex;
    gap: 2rem;
    width: 100%;
    flex-wrap: wrap;
  }

  .properties-column {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0.2rem 0.2rem;
    flex: 1;
    max-width: 100%;
  }

  .property {
    display: contents;
  }

  label {
    font-weight: bold;
    color: $color_primary;
  }

  span,
  input,
  textarea,
  select {
    width: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    margin-bottom: 1rem;
  }

  .edit-mode {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
    border: 1px solid $color_cyan_200;
    border-radius: 4px;
    font-size: 0.9rem;
  }
}

.rk-links-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;

    a {
      color: var(--primary-color);
      text-decoration: none;
      display: inline-block;
      padding: 0.5rem 0;
      transition: color 0.3s;

      &:hover {
        color: var(--primary-hover-color);
      }
    }
  }
}

.rk-edit-mode-links {
  max-height: 170px;
  display: flex;
  flex-direction: column;

  .rk-links-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 100%;
    overflow-y: auto;
  }

  .link-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
  }

  .link-fields {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    min-width: 0;
  }

  .input-field {
    width: 100%;
    padding: 0.4rem;
    border: 1px solid $color_cyan_200;
    border-radius: 4px;
    font-size: 0.9rem;
    margin-bottom: -0.2rem;
  }
}

.rk-error-message {
  display: block;
  width: 100%;
  flex: 1;
  grid-column: 2;
  color: $color_red_200;
  font-size: small;
}

.rk-array-item {
  display: flex;
  flex-wrap: wrap;
}

.rk-array-span {
  display: inline-block;
  width: 100px;
  text-align: left;
}

.rk-checkbox-container {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.rk-associate-check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rk-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}