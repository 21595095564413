.col-1-2 {
  width: 12.5%;
}

.col-11-2 {
  width: 87.5%;
}

@media (max-width: 1024px) {
  .col-1-2 {
    width: 20.5%;
  }

  .col-11-2 {
    width: 79.5%;
  }
}

@media (min-width: 1025px) and (max-width: 1550px) {
  .col-1-2 {
    width: 15.5%;
  }

  .col-11-2 {
    width: 84.5%;
  }
}
