$color_white: #ffffff;

$color_pink_100: #f57eb6;
$color_pink_200: #f04e98;
$color_pink_300: #e31c79;

$color_green_100: #c5e86c;
$color_green_200: #97d700;
$color_green_300: #78be21;

$color_turquoise_100: #9cdbd9;
$color_turquoise_200: #58c8c6;
$color_turquoise_300: #00b2a9;

$color_cyan_100: #71c5e8;
$color_cyan_200: #41b6e6;
$color_cyan_300: #00a3e0;
$color_cyan_400: #147bd1;

$color_blue_100: #7bafd4;
$color_blue_200: #0086d6;
$color_blue_300: #003c71;
$color_blue_400: #1d428a;
$color_blue_500: #003057;
$color_blue_600: #0033a0;

$color_yellow_100: #f9e547;
$color_yellow_200: #fdda25;
$color_yellow_300: #ffcd00;

$color_orange_100: #f1be48;
$color_orange_200: #f1b434;
$color_orange_300: #ed8b00;

$color_red_100: #ff585d;
$color_red_200: #f9423a;
$color_red_300: #e4002b;

$color_brown_thin_100: #b7a99a;
$color_brown_thin_200: #a39382;
$color_brown_thin_300: #7a6855;

$color_steel_thin_100: #a4bcc2;
$color_steel_thin_200: #98a4ae;
$color_steel_thin_300: #768692;

$color_blue_thin_100: #b7c9d3;
$color_blue_thin_200: #a6bbc8;
$color_blue_thin_300: #5b7f95;

$color_gray_thin_100: #c7c9c7;
$color_gray_thin_200: #9ea2a2;
$color_gray_thin_300: #707372;

$color_emerald_thin_100: #b7cdc2;
$color_emerald_thin_200: #9ab9ad;
$color_emerald_thin_300: #789f90;

$color_camel_thin_100: #dcbfa6;
$color_camel_thin_200: #cda788;
$color_camel_thin_300: #bf9474;

$color_wine_thin_100: #d7a3ab;
$color_wine_thin_200: #b46b7a;
$color_wine_thin_300: #874b52;

$color_ocher_thin_100: #d1ccbd;
$color_ocher_thin_200: #b7b09c;
$color_ocher_thin_300: #a69f88;

$color_coolgray_thin_300: #53565a;

$color_primary: $color_blue_600;
$color_primary_light: $color_gray_thin_100;
$color_secondary: $color_camel_thin_200;
$color_background: $color_white;
$color_font_primary: $color_coolgray_thin_300;
$color_font_secondary: $color_white;
