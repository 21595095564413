.wrapper {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .abs-center {
        background-color: $color_background;
        border-radius: 8px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        padding: 40px;
        text-align: center;
    }

    .lead {
        font-size: 24px;
        color: $color_cyan_200;
    }

    p {
        color: $color_gray_thin_300;
        line-height: 1.5;
    }
}