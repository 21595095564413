@import '../tokens/colors.scss';

.rk-background--primary {
  background-color: $color_primary;
}

.rk-background--primary-light {
  background-color: $color_primary_light;
}

.rk-text--primary {
  color: $color_primary;
}

.rk-text--invalid {
  color: $color_red_300;
}
