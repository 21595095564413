@import '../tokens/colors.scss';
@import '../foundations/texts.scss';

.rk-lego-action--workflow {
  color: $color_yellow_300;
}

.rk-lego-action--task {
  color: $color_green_300;
}

.rk-lego--selected {
  background-color: $color_blue_500;
  color: $color_white;
}

.rk-task-card {
  .p-card-title {
    @include rk-headline--h5;
  }
  .p-card-subtitle {
    color: black;
  }
  
  .p-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 15rem;
  }
}
