@import '../tokens/colors.scss';

.rk-toolbar {
  background-color: $color_white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px $color_blue_thin_100;
  padding: 0 1rem;
  flex-wrap: nowrap;
}

.rk-toolbar-user {
  border: 2px solid $color_blue_thin_100;
  background-color: $color_primary;
  padding: 0.5rem;
  border-radius: 2rem;
  color: $color_white;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rk-toolbar-user-image {
  width: 2rem;
  margin: 0 1rem;
}

.rt-floatmenu-user {
  z-index: 2;
  max-width: 280px;
  display: none;
  background: $color_primary;
  position: absolute;
  top: 60px;
  right: 40px;
  border: 1px solid $color_white;
  border-radius: 0.5rem;
  list-style: none;
  padding: 0;

  &.profile-menu_active {
    display: block;
    color: $color_white;
  }
}

.rt-floatmenu-user-access {
  padding: 1rem;
  text-transform: capitalize;
  border-bottom: 1px solid var(--surface-b);

  h4 {
    margin: 0;
  }

  span {
    font-size: 0.7rem;
  }
}

.rt-floatmenu-user-accions {
  display: flex;

  a {
    width: 100%;
    padding: 1rem;
    color: white;
    text-decoration: none;
    cursor: pointer;
  }

  span {
    font-size: 0.8rem;
  }
}

.rk-toolbar-cart {
  color: $color_primary;
  border: 2px solid $color_yellow_100;
  background-color: transparent;
  background-clip: padding-box;
  padding: 10px;

  .p-badge {
    font-size: 0.9rem;
    min-width: 1.1rem;
    height: 1.1rem;
    line-height: 1rem;
  }
}

.rk-toolbar-cart-badge--display {
  cursor: pointer;
  .p-badge {
    display: block;
  }
}

.rk-toolbar-cart-badge--hide {
  .p-badge {
    display: none;
  }
}

.rk-sidebar-icon {
  border: none;
  display: none;
}

@media (max-width: 768px) {

  .logo-img {
    width: 200px;
    height: auto;
  }

  .vertical-line,
  .company-name {
    display: none;
  }

  .rk-sidebar-icon {
    display: block;
    padding: 0.75rem;
    background: #FFFFFF;
    color: 	#000000;
  }
  
  .rk-sidebar-icon:hover {
    background-color: #d3e3fd;
  }
  
  .rk-sidebar-icon em {
    font-size: 1.2rem;
  }

  .rt-floatmenu-user {
    right: 10px;
  }
}