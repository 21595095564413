@import '../tokens/colors.scss';

.rk-last-added--background {
  background-color: $color_blue_100;
}

.rk-benfit {
  background-color: $color_green_300;
  color: $color_white;
}
