.rk-container {
    display: flex;
    background: linear-gradient(180deg, #0033A0 0%, rgb(77, 112, 189) 100%);
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
}

.rk-aside-bar {
    width: 4.7%;
    transition: width 0.4s ease;
}

.rk-aside-bar.isExpanded {
    width: 18.38%;
}

.rk-app-content-wrapper {
    box-shadow: 0px 7px 20px #00000040;
    height: 100%;
    position: relative;
    background: #f4f4f4;
    width: 100%;
    flex-grow: 1;
}

@media screen and (max-width: 768px) {
    .rk-container app-sidebar {
        transform: translateX(-100%);
    }

    .rk-container.header-menu-active app-sidebar {
        transform: translateX(0%);
        z-index: 2;
        transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
    }

    .rk-container.header-menu-active div.layout-mask {
        width: 100vw;
        height: 100vh;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        transition: background-color 1s;
        z-index: 1;
    }

    .rk-app-content-wrapper {
        width: 100vw;
        position: absolute;
        margin-left: 0;
    }

    .rk-aside-bar.isExpanded {
        width: 30%;
    }
}